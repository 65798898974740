/* 容器设置 */
.parallax-container {
    position: relative;
    width: 80%; /* 宽度填满屏幕 */
    height: 50vh; /* 初始高度为视口高度的 50% */
    background-image: url('../../public/images/blitzball_main3.png'); 
    background-size: cover; /* 图片按比例填充整个区域 */
    background-position: top; /* 显示顶部内容 */
    background-attachment: fixed; /* 背景固定，实现视差滚动效果 */
    overflow: hidden; /* 隐藏溢出 */
    margin: 0 auto;
    padding: 0;
    z-index: -1; /* 确保内容位于图片之上 */
}

/* 背景图片样式 */
.parallax-image {
    width: 50%;
    background-color: #fff; /* 保证背景颜色 */
    
}

/* 内容区域样式 */
.parallax-content {
  position: relative;
  color: white;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.5rem;
  z-index: 1; /* 确保内容位于图片之上 */
}
