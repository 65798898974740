.footer {
    display: grid;
    place-items: center;
    background-color: #ffffff;
    color: #000000;
    padding: 10px;
  }
  
  .footer p {
    margin: 0;
  }
  