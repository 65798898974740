.contact-form-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
  }
  
  .contact-form-container h2 {
    text-align: center;
  }
  
  .contact-form-container form div {
    margin-bottom: 10px;
  }
  
  .contact-form-container form label {
    display: block;
  }
  
  .contact-form-container form input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    font-size: 14px; /* 默认字体大小 */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .contact-form-container form textarea.message-box {
    width: 100%;
    height: 150px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }
  

  .contact-form-container form button {
    width: 100%;
    padding: 10px;
    background-color: #000000;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .contact-form-container form button:hover {
    background-color: #0056b3;
  }
  
  