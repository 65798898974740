/* 主容器 */
.header {
  text-align: center;
  padding: 5px 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #090606;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* 顶部布局 */
.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 左右分布 */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Logo 样式 */
.ych-yoyo-logo {
  width: 150px;
  height: auto;
  margin: 20px auto; /* 自动上下居中 */
  display: block; /* 确保在文档流中 */
}

/* 购物车图标 */
.cart-icon {
  position: relative; /* 保持相对布局 */
  margin-right: 0; /* 右对齐 */
}

.cart-icon a {
  text-decoration: none;
  color: #000;
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #ff4136;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* 标题样式 */
.logo {
  font-size: 24px;
  margin-top: 20px; /* 增加与 Logo 的垂直间距 */
  margin-bottom: 20px; /* 增加与导航栏的垂直间距 */
}

/* 导航栏 */
.nav-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* 增加导航项之间的间距 */
  margin-top: 10px; /* 确保底部有适当间距 */
}

.nav-links a {
  color: #060505;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.2s;
}

.nav-links a:hover {
  text-decoration: underline;
  color: #007bff;
}

/* 响应式布局 */
@media (max-width: 768px) {
  .header-top {
    padding: 0 10px; /* 缩小内边距 */
  }

  .ych-yoyo-logo {
    width: 120px; /* 在小屏幕下缩小 Logo */
  }

  .nav-links {
    flex-direction: column; /* 导航栏改为垂直排列 */
    gap: 10px;
  }
}
