.home-container {
    text-align: center;
  }
  .top-image {
    width: 50%;
    max-height: 700px; /* Adjust according to your design */
    object-fit: cover;
  }
  .youtube-video {
    width: 70%;
    height: 1000px;
    border: none;
    margin-top: 20px;
    margin-bottom: 100px;
  }
  
  @media only screen and (max-width: 600px) {
    .youtube-video {
      height: 300px;
    }
    .top-image {
        height: 300px;
      }
  }
  
  .highlight-text {
    color: #ffffff; /* 文字颜色为白色 */
    text-shadow: 1px 1px 2px #000000; /* 黑色文字边框效果 */
    font-weight: bold;
  }
  
  