.customizer {
    text-align: center;
    padding: 20px;
  }
  
.customizer p {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    margin: 0 auto;
  }


/* Customizer.css */
.canvas-container {
    width: 400px;
    height: 400px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  canvas {
    display: block;
    margin: 0 auto;
    background: transparent; /* 画布背景透明 */
  }
  
  .image-upload {
    margin: 20px 0;
  }
  
  .controls button {
    margin: 10px;
    display: inline-block;
    padding: 12px 20px;
    background-color: #4CAF50; /* Green */
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .controls button:active {
    transform: scale(0.95); /* 按下时缩小 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 按下时阴影缩小 */
  }
  
  
  .controls button:hover {
    background-color: white;
    color: black;
    border: 2px solid #4CAF50;
  }

  /* 禁用状态按钮样式 */
  .controls .add-to-cart-btn:disabled {
    background-color: #cccccc; /* 灰色背景 */
    color: #666666; /* 灰色文字 */
    cursor: not-allowed; /* 禁用时显示禁止图标 */
    box-shadow: none; /* 去掉阴影效果 */
  }

  .email button {
    margin: 10px;
    display: inline-block;
    padding: 12px 20px;
    background-color: #077e9c; /* Green */
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .email button:active {
    transform: scale(0.95); /* 按下时缩小 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 按下时阴影缩小 */
  }
  
  
  .email button:hover {
    background-color: white;
    color: black;
    border: 2px solid #159fc9;
  }
  